import {
  Box,
  ButtonGroup,
  Container,
  Divider,
  Flex,
  Icon,
  IconButton,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  copyrightText,
  footerContactInfo,
  footerPolicy,
  footerTitles,
  socialIcons,
} from "../utils/consts";
import Logo from "./Logo";

const Footer = () => {
  const { t } = useTranslation();
  const handleLinkClick = (title) => {
    if (title === "mail") {
      window.open("mailto:info@yukatech.com", "_self");
    }
  };

  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Container as={Stack} maxW="100%" py={6} bgColor="primaryColor">
        <Stack
          pt="6"
          pb="6"
          justifyContent="space-between"
          direction={{
            base: "column",
            md: "row",
          }}
          alignItems="center"
        >
          <Text
            variant={{
              sm: "small",
              md: "medium",
              lg: "large",
              xl: "xlarge",
            }}
            textAlign="center"
            fontSize="xl"
            color="whiteColor"
          >
            {t("only_motto_why_not")}
          </Text>
          <ButtonGroup spacing={4} style={{ marginLeft: "0" }} variant="ghost">
            {socialIcons.map((item, i) => (
              <IconButton
                _hover={{ color: "buttonColor" }}
                spacing={10}
                bgColor="whiteColor"
                key={i}
                as="a"
                icon={<item.icon fontSize="1.25em" />}
                href={item.path}
                style={{ marginRight: "8px" }}
              />
            ))}
          </ButtonGroup>
        </Stack>
        <Divider />
        <Container as={Stack} maxW="100%" py={6} bgColor="whiteColor">
          <SimpleGrid
            templateColumns={{ sm: "1fr", md: "repeat(5, 1fr)" }}
            spacing={8}
            margin="50px 0 50px 0 !important"
          >
            <Stack spacing={6} height="100%">
              <Logo
                logoWidthStyle={{
                  base: "100px",
                  sm: "150px",
                  md: "200px",
                  lg: "200px",
                  xl: "200px",
                }}
              />
              <Flex flexDirection="column" gap={3}>
                {footerContactInfo.map(({ title, icon }, index) => (
                  <Flex
                    margin="0"
                    gap={2}
                    key={index}
                    onClick={() => handleLinkClick(title)}
                    style={{ cursor: title === "mail" ? "pointer" : "default" }}
                  >
                    <Icon
                      color="primaryColor"
                      fontSize="25px"
                      as={icon}
                      marginLeft="0"
                    />
                    <Text color="primaryColor" margin="0">
                      {t(title)}
                    </Text>
                  </Flex>
                ))}
              </Flex>
            </Stack>
            {footerTitles.map(({ title, data }, index) => (
              <Stack align="flex-start" key={index}>
                <Stack align="flex-start">
                  <Text
                    fontWeight="700"
                    fontSize="lg"
                    mb={2}
                    color="primaryColor"
                    mt={10}
                  >
                    {t(title)}
                  </Text>
                  {data.map(({ name, link }, index) => (
                    <Link
                      key={index}
                      to={link}
                      fontWeight="400px"
                      fontSize="md"
                      mb={2}
                      color="primaryColor"
                    >
                      {t(name)}
                    </Link>
                  ))}
                </Stack>
              </Stack>
            ))}
          </SimpleGrid>
          <Stack spacing="4">
            <Stack
              spacing="4"
              direction={{
                base: "column",
                sm: "row",
              }}
              maxW={{
                lg: "360px",
              }}
            />
          </Stack>
          <Divider />
        </Container>
        <Stack
          pt="6"
          pb="6"
          color="whiteColor"
          justifyContent="space-between"
          margin="3 !important"
          direction={{
            base: "column-reverse",
            md: "row",
          }}
          alignItems="center"
        >
          <Text
            fontSize="sm"
            color="whiteColor"
            dangerouslySetInnerHTML={{ __html: copyrightText() }}
          />
          {footerPolicy.map(({ name, link }, index) => (
            <Link key={index} to={link} color="whiteColor" variant="link">
              {t(name)}
            </Link>
          ))}
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;