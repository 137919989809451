import React, { useState } from "react";
import {
  Flex,
  Box,
  FormLabel,
  Stack,
  Button,
  Heading,
  Text,
  ButtonGroup,
  SimpleGrid,
  Textarea,
  Icon,
  Container,
  Grid,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { footerContactInfo } from "../utils/consts";
import { AddressMap } from "./AdressMap";
import { Formik, Form } from "formik";
import { contactFormValue, careerFormList } from "../utils/consts";
import { ContactFormValidation } from "../utils/validations";
import FormikFormValidation from "./FormikFormValidation";
import { sendContactApi } from "../api/api";
import Logo from "./Logo";
import CustomAlert from "../components/CustomAlert";

export default function ContactBody() {
  const { t } = useTranslation();
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async (values, { resetForm, setFieldValue }) => {
    setSubmissionStatus("");
    const formData = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      message: values.message,
    };
    setIsSubmitting(true);
    resetForm();
    setFieldValue("message", "");
    const response = await sendContactApi(formData);
    if (response) {
      setSubmissionStatus("success");
    } else {
      setSubmissionStatus("error");
    }
    setIsSubmitting(false);
  };
  const handleLinkClick = (title) => {
    if (title === "mail") {
      window.open("mailto:info@yukatech.com", "_self");
    }
  };

  return (
    <>
      <AddressMap />
      <Box className="relative">
        <Container
          as={SimpleGrid}
          maxW="8xl"
          columns={{ base: 1, md: 2 }}
          spacing={{ base: 0, lg: 32 }}
          py={{ base: 10, sm: 20, lg: 32 }}
        >
          <Stack
            bg="gray.50"
            rounded="xl"
            p={{ base: 4, sm: 6, md: 8 }}
            spacing={{ base: 8 }}
          >
            <Stack spacing={4}>
              <Heading
                color="gray.800"
                lineHeight={1.1}
                fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
              >
                {t("contact_form")}
              </Heading>
            </Stack>
            <Formik
              initialValues={contactFormValue}
              validationSchema={ContactFormValidation()}
              onSubmit={handleSubmit}
            >
              {({ values, errors, handleChange, touched }) => (
                <Form>
                  <Stack spacing={4}>
                    {careerFormList.map(({ id, title, type, place }, i) => (
                      <FormikFormValidation
                        key={i}
                        id={id}
                        title={title}
                        type={type}
                        place={place}
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                      />
                    ))}
                    <FormLabel>{t("message")}</FormLabel>
                    <Textarea
                      name="message"
                      value={values.message}
                      onChange={handleChange}
                      resize="none"
                      placeholder={t("write_your_message")}
                    />
                  </Stack>
                  <ButtonGroup variant="outline" spacing="6">
                    <Grid
                      templateColumns={{ base: "1fr", lg: "1fr auto" }}
                      gap={4}
                      alignItems={{ base: "flex-start", lg: "center" }}
                    >
                      <Button
                        type="submit"
                        marginTop="20px"
                        bg="buttonColor"
                        color="whiteColor"
                        borderRadius="2xl"
                        colorScheme="buttonColor"
                        _hover={{ transform: "scale3d(1.05, 1.05, 1)" }}
                        isLoading={isSubmitting}
                        isDisabled={isSubmitting}
                      >
                        {isSubmitting ? t("submitting") : t("submit_form")}
                      </Button>
                      {submissionStatus && (
                        <CustomAlert
                          submissionStatus={submissionStatus}
                          successText={"form_submit"}
                          errorText={"form_failed"}
                        />
                      )}
                    </Grid>
                  </ButtonGroup>
                </Form>
              )}
            </Formik>
          </Stack>
          <Stack
            bg="gray.50"
            rounded="xl"
            p={{ base: 4, sm: 6, md: 8 }}
            spacing={{ base: 8 }}
          >
            <Stack spacing={4}>
              <Heading
                color="gray.800"
                lineHeight={1.1}
                fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
              >
                {t("company_info")}
              </Heading>
            </Stack>
            <Stack spacing={6} width="100%" height="100%">
              <Text fontSize="lg" color="gray.600">
                {t("meet")}
              </Text>
              <Flex
                marginTop="50px"
                marginBottom="100px"
                flexDirection="column"
                gap={3}
              >
                {footerContactInfo.map(({ title, icon }, index) => (
                  <Flex
                    alignItems="center"
                    gap={2}
                    key={index}
                    onClick={() => handleLinkClick(title)}
                    style={{
                      cursor: title === "mail" ? "pointer" : "default",
                    }}
                  >
                    <Icon color="buttonColor" fontSize="25px" as={icon} />
                    <Text color="buttonColor">{t(title)}</Text>
                  </Flex>
                ))}
              </Flex>
              <Logo />
            </Stack>
          </Stack>
        </Container>
      </Box>
    </>
  );
}
