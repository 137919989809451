import { BiPhoneCall } from "react-icons/bi";
import {
  FaFacebook,
  FaInstagram,
  FaLaptopCode,
  FaLinkedin,
} from "react-icons/fa";
import { GoMail } from "react-icons/go";
import { HiOutlineLocationMarker } from "react-icons/hi";
import danismanlik from "../assets/consult.png";
import digitalmarket from "../assets/digitalmarket.png";
import ecommerce from "../assets/ecommerce.png";
import en from "../assets/english.png";
import m2m from "../assets/m2m.png";
import nextwawi from "../assets/nextwawi.png";
import routecontrol from "../assets/routecontrol.png";
import tr from "../assets/turkish.png";
import TwitterLogo from "../assets/twitter.png";
import yukatel from "../assets/yukatel.png";
import yukatrack from "../assets/yukatrack.png";
import develop from "../assets/develop.png";
import ecom from "../assets/ecom.png";
import m2mmockup from "../assets/m2mmockup.png";
import market from "../assets/market.png";
import mockup from "../assets/mockup.png";
import nextmockup from "../assets/nextmockup.png";
import routemockup from "../assets/routemockup.png";
import web from "../assets/web.png";
import webyazilim from "../assets/websoftware.png";
import yukamockup from "../assets/yukamockup.png";
import { AiOutlineCloudServer, AiOutlineFileText } from "react-icons/ai";
import { BiCodeAlt } from "react-icons/bi";
import { DiGoogleAnalytics } from "react-icons/di";
import {
  FaBullhorn,
  FaCode,
  FaPencilRuler,
  FaRulerCombined,
} from "react-icons/fa";
import { FiTarget } from "react-icons/fi";
import { GiOnTarget } from "react-icons/gi";
import {
  MdCheckCircle,
  MdDevices,
  MdOutlineDesignServices,
} from "react-icons/md";
import { SiSpeedtest } from "react-icons/si";
import { TbFileCertificate } from "react-icons/tb";
import servicesimageandroidappfirst from "../assets/servicesimageandroidappfirst.jpeg";
import servicesimageandroidappsecond from "../assets/servicesimageandroidappsecond.jpeg";
import servicesimagecloudsolutionsfirst from "../assets/servicesimagecloudsolutionsfirst.jpeg";
import servicesimagecloudsolutionssecond from "../assets/servicesimagecloudsolutionssecond.jpeg";
import servicesimagecorporateidfirst from "../assets/servicesimagecorporateidfirst.jpeg";
import servicesimagecorporateidsecond from "../assets/servicesimagecorporateidsecond.jpeg";
import servicesimageecommercefirst from "../assets/servicesimageecommercefirst.jpeg";
import servicesimageecommercesecond from "../assets/servicesimageecommercesecond.jpeg";
import servicesimageemailsolutionfirst from "../assets/servicesimageemailsolutionfirst.jpeg";
import servicesimageemailsolutionsecond from "../assets/servicesimageemailsolutionsecond.jpeg";
import servicesimagegoogleadsfirst from "../assets/servicesimagegoogleadsfirst.jpeg";
import servicesimagegoogleadssecond from "../assets/servicesimagegoogleadssecond.jpeg";
import servicesimagegooglebusinessfirst from "../assets/servicesimagegooglebusinessfirst.jpeg";
import servicesimagegooglebusinesssecond from "../assets/servicesimagegooglebusinesssecond.jpeg";
import servicesimageiosappfirst from "../assets/servicesimageiosappfirst.jpeg";
import servicesimageiosappsecond from "../assets/servicesimageiosappsecond.jpeg";
import servicesimagenetworkandserversetupfirst from "../assets/servicesimagenetworkandserversetupfirst.jpeg";
import servicesimagenetworkandserversetupsecond from "../assets/servicesimagenetworkandserversetupsecond.jpeg";
import servicesimageoptimizationfirst from "../assets/servicesimageoptimizationfirst.jpeg";
import servicesimageoptimizationsecond from "../assets/servicesimageoptimizationsecond.jpeg";
import servicesimagesocialmediafirst from "../assets/servicesimagesocialmediafirst.jpeg";
import servicesimagesocialmediasecond from "../assets/servicesimagesocialmediasecond.jpeg";
import servicesimagesoftwaredevfirst from "../assets/servicesimagesoftwaredevfirst.jpeg";
import servicesimagesoftwaredevsecond from "../assets/servicesimagesoftwaredevsecond.jpeg";
import servicesimagesslservicefirst from "../assets/servicesimagesslservicefirst.jpeg";
import servicesimagesslservicesecond from "../assets/servicesimagesslservicesecond.jpeg";
import servicesimagesupportcarefirst from "../assets/servicesimagesupportcarefirst.jpeg";
import servicesimagesupportcaresecond from "../assets/servicesimagesupportcaresecond.jpeg";
import servicesimagewebsolutionfirst from "../assets/servicesimagewebsolutionfirst.jpeg";
import servicesimagewebsolutionsecond from "../assets/servicesimagewebsolutionsecond.jpeg";
import blogBodyHeaderImage from "../assets/blogHeaderImage.jpg";
import { useTranslation } from "react-i18next";
import { getCurrentYear } from "./helpers";

export const languageOptions = new Map([
  ["tr", tr],
  ["en", en],
]);

export const typedTexts = [
  "web_solution",
  "software_development",
  "digital_marketing",
  "consultancy_services",
];

export const contactLinkList = [
  { title: "career", link: "/career" },
  { title: "blog", link: "/blog_page" },
];

export const flipCardData = [
  {
    label: "web_design",
    title: "web_design_banner",
    image: ecommerce,
    icon: ecom,
  },
  {
    label: "software_development",
    title: "software_banner",
    image: webyazilim,
    icon: web,
  },
  {
    label: "digital_marketing",
    title: "digital_banner",
    image: digitalmarket,
    icon: develop,
  },
  {
    label: "consultancy_services",
    title: "consultancy_banner",
    image: danismanlik,
    icon: market,
  },
];

export const aboutData = [
  {
    title: "about",
    info: "about_us_details",
  },
  {
    title: "financial_business_information",
    info: "financial_info",
  },
  {
    title: "privacy_security",
    info: "privacy_info",
  },
];

export const modalCardLists = [
  {
    id: "0",
    title: "web_design",
    info: "web_design_content",
    icon: FaPencilRuler,
    hiddenTitle: "web_design_service",
    hiddenButtons: [
      "corporate_website",
      "e_commerce_site",
      "special_project",
      "consultancy",
    ],
  },
  {
    id: "1",
    title: "software_development",
    info: "software_content",
    icon: FaCode,
    hiddenTitle: "software_service",
    hiddenButtons: [
      "android_mobil_app",
      "ios_mobil_app",
      "software_development_for_corporates",
      "e_commerce_project",
    ],
  },
  {
    id: "2",
    title: "consultancy_services",
    info: "consultant_content",
    icon: FaRulerCombined,
    hiddenTitle: "consultant_service",
    hiddenButtons: ["maintenance_support", "network_server", "cloud_solution"],
  },
  {
    id: "3",
    title: "digital_marketing",
    info: "digital_content",
    icon: FaBullhorn,
    hiddenTitle: "digital_service",
    hiddenButtons: [
      "google_ads",
      "e_commerce_project",
      "special_project",
      "consultancy",
    ],
  },
];

export const navbarLinkLists = [
  {
    name: "services",
    subMenu: true,
    sublinks: [
      {
        headTitle: "web_solution",
        subLink: [
          { name: "website_design", link: "/web_solution" },
          { name: "search_engine_optimization", link: "/optimization" },
          { name: "ssl_certificate_services", link: "/ssl_service" },
          { name: "email_solutions", link: "/email_solution" },
        ],
      },
      {
        headTitle: "software_development",
        subLink: [
          { name: "android_mobil_app", link: "/android_app" },
          { name: "ios_mobil_app", link: "/ios_app" },
          {
            name: "software_development_for_corporates",
            link: "/software_dev",
          },
          { name: "e_commerce_project", link: "/ecommerce" },
        ],
      },
      {
        headTitle: "digital_marketing",
        subLink: [
          { name: "corporate_identity_studies", link: "/corporateid" },
          { name: "social_media_management", link: "/social_media" },
          { name: "google_ads", link: "/google_ads" },
          { name: "google_business_management", link: "/google_business" },
        ],
      },
      {
        headTitle: "consultancy_services",
        subLink: [
          { name: "maintenance_support", link: "/support_care" },
          { name: "network_server", link: "/network_server" },
          { name: "cloud_solution", link: "/cloud_solution" },
        ],
      },
    ],
  },
  {
    name: "corporate",
    subMenu: true,
    sublinks: [
      {
        headTitle: "who_us",
        link: "/about",
        subLink: [
          {
            name: "",
            link: "/about",
          },
        ],
      },
    ],
  },
];

export const brandSliderCard = [
  {
    image: mockup,
    title: "yukatel_brand",
    text: "yukatel_content",
    service: "service_provided",
    software: "software_ux",
    link: "https://yukatel.de/en/",
  },
  {
    image: yukamockup,
    title: "yukatrack_brand",
    text: "yukatrack_content",
    service: "service_provided",
    software: "software_ux",
    link: "https://yukatrack.com/",
  },
  {
    image: nextmockup,
    title: "nextwawi_brand",
    text: "nextwawi_content",
    service: "service_provided",
    software: "software_ux",
    link: "https://nextwawi.com/",
  },
  {
    image: routemockup,
    title: "routecontrol_brand",
    text: "routecontrol_content",
    service: "service_provided",
    software: "software_ux",
    link: "https://www.routecontrol.de/",
  },
  {
    image: m2mmockup,
    title: "m2m_brand",
    text: "m2m_content",
    service: "service_provided",
    software: "software_ux",
    link: "https://m2m-unity.com/en_gb/",
  },
];

export const brandData = [m2m, nextwawi, yukatel, routecontrol, yukatrack];
export const Twitter = () => {
  return (
    <div>
      <img
        src={TwitterLogo}
        alt="Twitter"
        width="20"
        height="20"
        align="center"
      ></img>
    </div>
  );
};
export const socialIcons = [
  {
    icon: FaFacebook,
    path: "https://www.facebook.com/profile.php?id=100070604360212",
  },

  {
    icon: FaInstagram,
    path: "https://www.instagram.com/yuka.tech/",
  },
  {
    icon: FaLinkedin,
    path: "https://www.linkedin.com/company/routecontrol/mycompany/verification/",
  },
  {
    icon: Twitter,
    path: "https://twitter.com/i/flow/login?redirect_after_login=%2FYukatechBilisim",
  },
];

export const footerTitles = [
  {
    title: "web_solution",
    data: [
      { name: "website_design", link: "/web_solution" },
      { name: "search_engine_optimization", link: "/optimization" },
      { name: "ssl_certificate_services", link: "/ssl_service" },
      { name: "email_solutions", link: "/email_solution" },
    ],
  },
  {
    title: "software_development",
    data: [
      { name: "android_mobil_app", link: "/android_app" },
      { name: "ios_mobil_app", link: "/ios_app" },
      { name: "software_development_for_corporates", link: "/software_dev" },
      { name: "e_commerce_project", link: "/ecommerce" },
    ],
  },
  {
    title: "digital_marketing",
    data: [
      { name: "corporate_identity_studies", link: "/corporateid" },
      { name: "social_media_management", link: "/social_media" },
      { name: "google_ads", link: "/google_ads" },
      { name: "google_business_management", link: "/google_business" },
    ],
  },
  {
    title: "consultancy_services",
    data: [
      { name: "maintenance_support", link: "/support_care" },
      { name: "network_server", link: "/network_server" },
      { name: "cloud_solution", link: "/cloud_solution" },
    ],
  },
];

export const footerContactInfo = [
  {
    icon: HiOutlineLocationMarker,
    title: "address",
  },
  { icon: BiPhoneCall, title: "phone_number" },
  { icon: GoMail, title: "mail" },
];

export const processCardData = [
  {
    number: "01",
    icon: MdOutlineDesignServices,
    title: "original_design",
    subtitle: "original_design_content",
  },
  {
    number: "02",
    icon: MdDevices,
    title: "full_mobile_compatibility",
    subtitle: "full_mobile_compatibility_content",
  },
  {
    number: "03",
    icon: FaLaptopCode,
    title: "clean_coding",
    subtitle: "clean_coding_content",
  },
  {
    number: "04",
    icon: SiSpeedtest,
    title: "overclocking",
    subtitle: "overclocking_content",
  },
  {
    number: "05",
    icon: TbFileCertificate,
    title: "web_standards",
    subtitle: "web_standards_content",
  },
  {
    number: "06",
    icon: GiOnTarget,
    title: "seo_impact",
    subtitle: "seo_impact_content",
  },
];
export const footerPolicy = [
  {
    name: "kvkk",
    link: "/kvkk_policy",
  },
  {
    name: "privacy_policy",
    link: "/privacy_policy",
  },
];

export const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  pauseOnHover: true,
  initialSlide: 0,
  centerMode: true,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 375,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
  ],
};

export const careerFormOption = [
  { choice: "optionfrontend", title: "frontend_developer" },
  { choice: "optionbackend", title: "backend_developer" },
  { choice: "optionmobil", title: "mobile_developer" },
  { choice: "optionintern", title: "intern" },
  { choice: "optionother", title: "other" },
];

export const webProcess = [
  {
    number: "01",
    title: "goal_setting",
    icon: FiTarget,
    subtitle: "goal_setting_subtitle",
  },
  {
    number: "02",
    icon: DiGoogleAnalytics,
    title: "research_strategy",
    subtitle: "research_strategy_subtitle",
  },
  {
    number: "03",
    icon: AiOutlineFileText,
    title: "content_planning",
    subtitle: "content_planning_subtitle",
  },
  {
    number: "04",
    icon: MdDevices,
    title: "design_planning",
    subtitle: "design_planning_subtitle",
  },
  {
    number: "05",
    icon: BiCodeAlt,
    title: "coding_testing_processes",
    subtitle: "coding_testing_processes_subtitle",
  },
  {
    number: "06",
    icon: AiOutlineCloudServer,
    title: "publishing",
    subtitle: "publishing_subtitle",
  },
];
export const androidProcess = [
  {
    number: "01",
    title: "goal_setting",
    icon: FiTarget,
    subtitle: "android_app_goal_setting",
  },
  {
    number: "02",
    icon: DiGoogleAnalytics,
    title: "research_strategy",
    subtitle: "android_app_research_and_strategy",
  },
  {
    number: "03",
    icon: AiOutlineFileText,
    title: "content_planning",
    subtitle: "android_app_content_planning",
  },
  {
    number: "04",
    icon: MdDevices,
    title: "design_planning",
    subtitle: "android_app_design_planning",
  },
  {
    number: "05",
    icon: BiCodeAlt,
    title: "coding_testing_processes",
    subtitle: "android_app_coding_and_testing_processes",
  },
  {
    number: "06",
    icon: AiOutlineCloudServer,
    title: "publishing",
    subtitle: "publishing_subtitle",
  },
];
export const corporatedProcess = [
  {
    number: "01",
    title: "goal_setting",
    icon: FiTarget,
    subtitle: "corporateid_goal_setting",
  },
  {
    number: "02",
    icon: DiGoogleAnalytics,
    title: "research_strategy",
    subtitle: "corporateid_research_and_strategy",
  },
  {
    number: "03",
    icon: AiOutlineFileText,
    title: "content_planning",
    subtitle: "corporateid_content_planning",
  },
  {
    number: "04",
    icon: MdDevices,
    title: "design_planning",
    subtitle: "corporateid_design_planning",
  },
  {
    number: "05",
    icon: BiCodeAlt,
    title: "coding_testing_processes",
    subtitle: "corporateid_coding_and_testing_processes",
  },
  {
    number: "06",
    icon: AiOutlineCloudServer,
    title: "publishing",
    subtitle: "corporateid_publishing",
  },
];
export const supportProcess = [
  {
    number: "01",
    title: "goal_setting",
    icon: FiTarget,
    subtitle: "support_care_goal_setting",
  },
  {
    number: "02",
    icon: DiGoogleAnalytics,
    title: "research_strategy",
    subtitle: "support_care_research_and_strategy",
  },
  {
    number: "03",
    icon: AiOutlineFileText,
    title: "content_planning",
    subtitle: "support_care_content_planning",
  },
  {
    number: "04",
    icon: MdDevices,
    title: "design_planning",
    subtitle: "support_care_design_planning",
  },
  {
    number: "05",
    icon: BiCodeAlt,
    title: "coding_testing_processes",
    subtitle: "support_care_coding_and_testing_processes",
  },
  {
    number: "06",
    icon: AiOutlineCloudServer,
    title: "publishing",
    subtitle: "support_care_publishing",
  },
];
export const careerFormValue = {
  name: "",
  email: "",
  phone: "",
  position: "",
  description: "",
  file: null,
};

export const careerFormList = [
  {
    id: "name",
    title: "name",
    type: "text",
    place: "name",
  },
  {
    id: "email",
    title: "email",
    type: "email",
    place: "email",
  },
  {
    id: "phone",
    title: "phone",
    type: "number",
    place: "phone",
  },
];

export const contactFormValue = {
  name: "",
  email: "",
  phone: "",
  message: "",
};

export const offerFormValue = {
  name: "",
  email: "",
  company: "",
  number_value: "",
  accept: "",
};

export const offerFormList = [
  {
    id: "name",
    title: "name",
    type: "text",
    place: "name",
  },
  {
    id: "company",
    title: "company",
    type: "text",
    place: "company_name",
  },
  {
    id: "number_value",
    title: "number_value",
    type: "tel",
    place: "phone",
  },
  {
    id: "email",
    title: "email",
    type: "email",
    place: "email",
  },
];

export const blogCard = [
  {
    blogImage: "https://r.resimlink.com/rhoAJE.jpg",
    title: "blog",
    header_text: "blog_card_header1",
    content: "blog_card_text1",
    blog_text: "blog_text1",
  },

  {
    blogImage: "https://r.resimlink.com/MFjQPoVq0XRr.jpg",
    title: "blog",
    header_text: "blog_card_header2",
    content: "blog_card_text2",
    blog_text: "blog_text2",
  },
  {
    blogImage: "https://r.resimlink.com/phl5I.jpg",
    title: "blog",
    header_text: "blog_card_header3",
    content: "blog_card_text3",
    blog_text: "blog_text3",
  },
  {
    blogImage: "https://r.resimlink.com/hvJZz.jpg",
    title: "blog",
    header_text: "blog_card_header4",
    content: "blog_card_text4",
    blog_text: "blog_text4",
  },
];
export const milestones = [
  {
    id: 1,
    categories: ["Article"],
    title: "milestones_article_title",
    icon: MdCheckCircle,
    description: "info",
    date: "MARCH 30, 2022",
  },
  {
    id: 2,
    categories: ["Web Dev", "OSS"],
    title: "milestones_oss_title",
    icon: MdCheckCircle,
    description: "info",
    date: "July 30, 2022",
  },
];
export const servicesStageList = [
  {
    id: 1,
    icon: MdCheckCircle,
    content: "original_design_content",
  },
  {
    id: 2,
    icon: MdCheckCircle,
    content: "original_design_content",
  },
  {
    id: 3,
    icon: MdCheckCircle,
    content: "original_design_content",
  },
  {
    id: 4,
    icon: MdCheckCircle,
    content: "original_design_content",
  },
  {
    id: 5,
    icon: MdCheckCircle,
    content: "original_design_content",
  },
];
export const websolution = [
  {
    id: 1,
    icon: MdCheckCircle,
    content: "web_solution_article1",
  },
  {
    id: 2,
    icon: MdCheckCircle,
    content: "web_solution_article2",
  },
  {
    id: 3,
    icon: MdCheckCircle,
    content: "web_solution_article3",
  },
  {
    id: 4,
    icon: MdCheckCircle,
    content: "web_solution_article4",
  },
  {
    id: 5,
    icon: MdCheckCircle,
    content: "web_solution_article5",
  },
];
export const optimization = [
  {
    id: 1,
    icon: MdCheckCircle,
    content: "optimization_article2",
  },
  {
    id: 2,
    icon: MdCheckCircle,
    content: "optimization_article3",
  },
  {
    id: 3,
    icon: MdCheckCircle,
    content: "optimization_article4",
  },
  {
    id: 4,
    icon: MdCheckCircle,
    content: "optimization_article5",
  },
];
export const ssl_service = [
  {
    id: 1,
    icon: MdCheckCircle,
    content: "ssl_article2",
  },
  {
    id: 2,
    icon: MdCheckCircle,
    content: "ssl_article3",
  },
  {
    id: 3,
    icon: MdCheckCircle,
    content: "ssl_article4",
  },
  {
    id: 4,
    icon: MdCheckCircle,
    content: "ssl_article5",
  },
];
export const email_solution = [
  {
    id: 1,
    icon: MdCheckCircle,
    content: "email_article1",
  },
  {
    id: 2,
    icon: MdCheckCircle,
    content: "email_article2",
  },
  {
    id: 3,
    icon: MdCheckCircle,
    content: "email_article3",
  },
  {
    id: 4,
    icon: MdCheckCircle,
    content: "email_article4",
  },
  {
    id: 5,
    icon: MdCheckCircle,
    content: "email_article5",
  },
];
export const android_app = [
  {
    id: 1,
    icon: MdCheckCircle,
    content: "android_article1",
  },
  {
    id: 2,
    icon: MdCheckCircle,
    content: "android_article2",
  },
  {
    id: 3,
    icon: MdCheckCircle,
    content: "android_article3",
  },
  {
    id: 4,
    icon: MdCheckCircle,
    content: "android_article4",
  },
  {
    id: 5,
    icon: MdCheckCircle,
    content: "android_article5",
  },
];
export const ios_app = [
  {
    id: 1,
    icon: MdCheckCircle,
    content: "ios_article1",
  },
  {
    id: 2,
    icon: MdCheckCircle,
    content: "ios_article2",
  },
  {
    id: 3,
    icon: MdCheckCircle,
    content: "ios_article3",
  },
  {
    id: 4,
    icon: MdCheckCircle,
    content: "ios_article4",
  },
  {
    id: 5,
    icon: MdCheckCircle,
    content: "ios_article5",
  },
];
export const software_dev = [
  {
    id: 1,
    icon: MdCheckCircle,
    content: "software_article1",
  },
  {
    id: 2,
    icon: MdCheckCircle,
    content: "software_article2",
  },
  {
    id: 3,
    icon: MdCheckCircle,
    content: "software_article3",
  },
  {
    id: 4,
    icon: MdCheckCircle,
    content: "software_article4",
  },
  {
    id: 5,
    icon: MdCheckCircle,
    content: "software_article5",
  },
];
export const e_commerce = [
  {
    id: 1,
    icon: MdCheckCircle,
    content: "ecommerce_article1",
  },
  {
    id: 2,
    icon: MdCheckCircle,
    content: "ecommerce_article2",
  },
  {
    id: 3,
    icon: MdCheckCircle,
    content: "ecommerce_article3",
  },
  {
    id: 4,
    icon: MdCheckCircle,
    content: "ecommerce_article4",
  },
  {
    id: 5,
    icon: MdCheckCircle,
    content: "ecommerce_article5",
  },
];
export const corporate_id = [
  {
    id: 1,
    icon: MdCheckCircle,
    content: "corporate_id_article1",
  },
  {
    id: 2,
    icon: MdCheckCircle,
    content: "corporate_id_article2",
  },
  {
    id: 3,
    icon: MdCheckCircle,
    content: "corporate_id_article3",
  },
  {
    id: 4,
    icon: MdCheckCircle,
    content: "corporate_id_article4",
  },
  {
    id: 5,
    icon: MdCheckCircle,
    content: "corporate_id_article5",
  },
];
export const social_media = [
  {
    id: 1,
    icon: MdCheckCircle,
    content: "social_media_article1",
  },
  {
    id: 2,
    icon: MdCheckCircle,
    content: "social_media_article2",
  },
  {
    id: 3,
    icon: MdCheckCircle,
    content: "social_media_article3",
  },
  {
    id: 4,
    icon: MdCheckCircle,
    content: "social_media_article4",
  },
  {
    id: 5,
    icon: MdCheckCircle,
    content: "social_media_article5",
  },
];
export const google_ads = [
  {
    id: 1,
    icon: MdCheckCircle,
    content: "google_adds_article1",
  },
  {
    id: 2,
    icon: MdCheckCircle,
    content: "google_adds_article2",
  },
  {
    id: 3,
    icon: MdCheckCircle,
    content: "google_adds_article3",
  },
  {
    id: 4,
    icon: MdCheckCircle,
    content: "google_adds_article4",
  },
  {
    id: 5,
    icon: MdCheckCircle,
    content: "google_adds_article5",
  },
];
export const google_business = [
  {
    id: 1,
    icon: MdCheckCircle,
    content: "google_business_article1",
  },
  {
    id: 2,
    icon: MdCheckCircle,
    content: "google_business_article2",
  },
  {
    id: 3,
    icon: MdCheckCircle,
    content: "google_business_article3",
  },
  {
    id: 4,
    icon: MdCheckCircle,
    content: "google_business_article4",
  },
  {
    id: 5,
    icon: MdCheckCircle,
    content: "google_business_article5",
  },
];
export const support_care = [
  {
    id: 1,
    icon: MdCheckCircle,
    content: "support_care_article1",
  },
  {
    id: 2,
    icon: MdCheckCircle,
    content: "support_care_article2",
  },
  {
    id: 3,
    icon: MdCheckCircle,
    content: "support_care_article3",
  },
  {
    id: 4,
    icon: MdCheckCircle,
    content: "support_care_article4",
  },
  {
    id: 5,
    icon: MdCheckCircle,
    content: "support_care_article5",
  },
];
export const network_server = [
  {
    id: 1,
    icon: MdCheckCircle,
    content: "network_server_article1",
  },
  {
    id: 2,
    icon: MdCheckCircle,
    content: "network_server_article2",
  },
  {
    id: 3,
    icon: MdCheckCircle,
    content: "network_server_article3",
  },
  {
    id: 4,
    icon: MdCheckCircle,
    content: "network_server_article4",
  },
  {
    id: 5,
    icon: MdCheckCircle,
    content: "network_server_article5",
  },
];
export const cloud_solution = [
  {
    id: 1,
    icon: MdCheckCircle,
    content: "cloud_solution_article1",
  },
  {
    id: 2,
    icon: MdCheckCircle,
    content: "cloud_solution_article2",
  },
  {
    id: 3,
    icon: MdCheckCircle,
    content: "cloud_solution_article3",
  },
  {
    id: 4,
    icon: MdCheckCircle,
    content: "cloud_solution_article4",
  },
  {
    id: 5,
    icon: MdCheckCircle,
    content: "cloud_solution_article5",
  },
];
export const servicesBodyImages = [
  {
    pageName: "ssl_certificate",
    image: [
      { servicesimagefirst: servicesimagesslservicefirst },
      { servicesimagesecond: servicesimagesslservicesecond },
    ],
  },
  {
    pageName: "network_server_solutionr",
    image: [
      { servicesimagefirst: servicesimagenetworkandserversetupfirst },
      { servicesimagesecond: servicesimagenetworkandserversetupsecond },
    ],
  },
  {
    pageName: "cloud_solutions",
    image: [
      { servicesimagefirst: servicesimagecloudsolutionsfirst },
      { servicesimagesecond: servicesimagecloudsolutionssecond },
    ],
  },
  {
    pageName: "goodle_ads_solution",
    image: [
      { servicesimagefirst: servicesimagegoogleadsfirst },
      { servicesimagesecond: servicesimagegoogleadssecond },
    ],
  },
  {
    pageName: "web_solution",
    image: [
      { servicesimagefirst: servicesimagewebsolutionfirst },
      { servicesimagesecond: servicesimagewebsolutionsecond },
    ],
  },
  {
    pageName: "optimization",
    image: [
      { servicesimagefirst: servicesimageoptimizationfirst },
      { servicesimagesecond: servicesimageoptimizationsecond },
    ],
  },
  {
    pageName: "support_care_solution",
    image: [
      { servicesimagefirst: servicesimagesupportcarefirst },
      { servicesimagesecond: servicesimagesupportcaresecond },
    ],
  },
  {
    pageName: "business_solution",
    image: [
      { servicesimagefirst: servicesimagegooglebusinessfirst },
      { servicesimagesecond: servicesimagegooglebusinesssecond },
    ],
  },
  {
    pageName: "email_solution",
    image: [
      { servicesimagefirst: servicesimageemailsolutionfirst },
      { servicesimagesecond: servicesimageemailsolutionsecond },
    ],
  },
  {
    pageName: "android_solution",
    image: [
      { servicesimagefirst: servicesimageandroidappfirst },
      { servicesimagesecond: servicesimageandroidappsecond },
    ],
  },
  {
    pageName: "ios_app_solution",
    image: [
      { servicesimagefirst: servicesimageiosappfirst },
      { servicesimagesecond: servicesimageiosappsecond },
    ],
  },
  {
    pageName: "social_media_solution",
    image: [
      { servicesimagefirst: servicesimagesocialmediafirst },
      { servicesimagesecond: servicesimagesocialmediasecond },
    ],
  },
  {
    pageName: "corporateid_solution",
    image: [
      { servicesimagefirst: servicesimagecorporateidfirst },
      { servicesimagesecond: servicesimagecorporateidsecond },
    ],
  },
  {
    pageName: "ecommerce_solution",
    image: [
      { servicesimagefirst: servicesimageecommercefirst },
      { servicesimagesecond: servicesimageecommercesecond },
    ],
  },
  {
    pageName: "software_dev_solution",
    image: [
      { servicesimagefirst: servicesimagesoftwaredevfirst },
      { servicesimagesecond: servicesimagesoftwaredevsecond },
    ],
  },
];

export const alertMessage = {
  success: "success",
  error: "error",
};

export const copyrightText = () => {
  const { t } = useTranslation();
  return `&copy; ${getCurrentYear()} ${t("copyright")}`;
};

export const perPage = 9;

export const currentDate = new Date().toISOString().slice(0, 10);

export const blogImage = {
  image: blogBodyHeaderImage,
};

export const HTML_TAG_REGEX = /<[^>]*>?/gm;

export const IMG_SRC_REGEX = /<img [^>]*src="([^"]*)"[^>]*>/i;


export const masterAdmin = "master admin"
export const admin= "admin"
